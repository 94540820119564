.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-one {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 7px 10px; /* Add padding to increase space around text */
    border-radius: 4px;
    z-index: 1;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
    white-space: nowrap; /* Prevent line breaks */
    width: auto;
    margin-top: 10px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.45;
    font-size: 0.857rem;
    &.top {
      top: -50px;
    }
    &.right {
      top: 0 !important;
      left: 100px;
    }
    &.left {
        top: -12px;
        left: -40px;
    }
  }
  
  .tooltip-container:hover .tooltip-one {
    opacity: 1;
  }